import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CarnetForm.css';
import heic2any from 'heic2any';
import logo from './LogoAzul.png'; // Reemplaza esto con la ruta de tu logo

const CarnetForm = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    aka: '',
    genero: '',
    ciudad: '',
    perfilImagen: null,
  });
  const [isConverting, setIsConverting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const convertToPNG = async (file) => {
    const isHEIC = file.type === 'image/heic' || file.name.toLowerCase().endsWith('.heic');

    if (isHEIC) {
      const blob = await heic2any({ blob: file, toType: 'image/png', quality: 0.9 });
      return new File([blob], file.name.replace(/\.\w+$/, '.png'), { type: 'image/png' });
    } else {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const reader = new FileReader();

        reader.onload = (event) => {
          img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            canvas.toBlob((blob) => {
              if (!blob) {
                reject(new Error('Error al convertir la imagen a PNG'));
                return;
              }
              const pngFile = new File([blob], file.name.replace(/\.\w+$/, '.png'), { type: 'image/png' });
              resolve(pngFile);
            }, 'image/png', 0.9);
          };
          img.src = event.target.result;
        };

        reader.onerror = () => reject(new Error('Error al leer la imagen'));
        reader.readAsDataURL(file);
      });
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsConverting(true);
      try {
        const pngFile = await convertToPNG(file);
        setFormData({ ...formData, perfilImagen: pngFile });
        toast.success('Imagen convertida a PNG');
      } catch (error) {
        toast.error('Error al convertir la imagen');
      } finally {
        setIsConverting(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.perfilImagen) {
      toast.error('Por favor, sube una imagen de perfil');
      return;
    }

    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('nombre', formData.nombre);
    formDataObj.append('aka', formData.aka);
    formDataObj.append('genero', formData.genero);
    formDataObj.append('ciudad', formData.ciudad);
    formDataObj.append('perfilImagen', formData.perfilImagen);
    formDataObj.append('carnetCreado', false);

    const toastId = toast.info('Enviando...', { autoClose: false });

    try {
      const response = await fetch('https://backaleron.aleronclub.com/api/carnets', {
        method: 'POST',
        body: formDataObj,
      });

      if (response.ok) {
        toast.update(toastId, {
          render: '¡Carnet creado con éxito!',
          type: 'success',
          autoClose: 3000,
        });
        setFormData({
          nombre: '',
          aka: '',
          genero: '',
          ciudad: '',
          perfilImagen: null,
        });
        document.getElementById('perfilImagen').value = null;
      } else {
        toast.update(toastId, {
          render: 'Error al crear el carnet',
          type: 'error',
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.update(toastId, {
        render: 'Error en la conexión',
        type: 'error',
        autoClose: 3000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="form-container">
      <form className="carnet-form" onSubmit={handleSubmit}>
        <img src={logo} alt="Logo" className="logo" />

        <input
          type="text"
          name="nombre"
          placeholder="Nombre"
          value={formData.nombre}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="aka"
          placeholder="A.K.A"
          value={formData.aka}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="genero"
          placeholder="Género"
          value={formData.genero}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="ciudad"
          placeholder="Ciudad"
          value={formData.ciudad}
          onChange={handleChange}
          required
        />
        <input
          type="file"
          id="perfilImagen"
          name="perfilImagen"
          accept="image/*"
          onChange={handleFileChange}
          required
        />
        <button
          type="submit"
          disabled={!formData.perfilImagen || isConverting || isSubmitting}
        >
          {isConverting
            ? 'Convirtiendo imagen...'
            : isSubmitting
            ? 'Enviando...'
            : 'Crear Carnet'}
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default CarnetForm;
